import "./_app.scss";
import "regenerator-runtime/runtime";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { NextPage } from "next";
import Head from "next/head";
import { AppContext, AppProps } from "next/app";
import { message, App, ConfigProvider, Modal } from "antd";
import { configResponsive } from "ahooks";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import zhCN from "antd/locale/zh_CN";
import { IUserInfo } from "common/interface/login";
import { GlobalProvider } from "common/hook/context";

dayjs.locale("zh-cn");
type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type Props = {
  userInfo: IUserInfo;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  userInfo: IUserInfo | null;
};

message.config({ top: 70 });

configResponsive({
  small: 0,
  middle: 1340,
  large: 1920
});

export default function MyApp(props: AppPropsWithLayout & Props) {
  const { pageProps, Component, userInfo } = props;
  const [favicon, setFavicon] = useState("/images/logo.svg");

  useEffect(() => {
    const cacheLoginInfo: any = localStorage.getItem("loginInfo");
    const is_company_customized: any = localStorage.getItem("is_company_customized");
    if (cacheLoginInfo && JSON.parse(cacheLoginInfo)) {
      const cacheInfo = JSON.parse(JSON.parse(cacheLoginInfo));
      const showLogo = JSON.parse(is_company_customized);
      setFavicon(
        showLogo?.show ||
          (!is_company_customized && cacheInfo?.settings?.company_functions?.is_company_customized)
          ? cacheInfo?.company?.logo
          : ""
      );
    }
  });
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta name="referrer" content="no-referrer" />
        <title>{(pageProps as any)?.title || "Maner"}</title>
        <link
          rel="shortcut icon"
          href={(pageProps as any)?.data?.logo || favicon || "/images/logo.svg"}
          type="image/png"
        />
      </Head>
      <ConfigProvider
        theme={{
          token: { colorPrimary: "#546FFF" },
          components: {
            Input: {
              colorBorder: "#F5F5F7"
            },
            Select: {
              colorBorder: "#F5F5F7"
            },
            Table: {
              headerColor: "#54577A"
            }
          }
        }}
        locale={zhCN}
      >
        <App>
          <GlobalProvider userInfo={userInfo}>
            <Component {...pageProps} />
          </GlobalProvider>
        </App>
      </ConfigProvider>
    </>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps: any = {};
  if (Component.getInitialProps) {
    pageProps = (await Component.getInitialProps(ctx)) || pageProps;
  }

  return {
    userInfo: null,
    pageProps
  };
};
