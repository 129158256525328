export const numberToChineseMap: { [key: number]: string } = {
  1: "一",
  2: "二",
  3: "三",
  4: "四",
  5: "五",
  6: "六",
  7: "七",
  8: "八",
  9: "九",
  10: "十",
  11: "十一",
  12: "十二"
};

// 信息展示类型 0不展示 1基本信息 2需求描述 3产品信息 4参考答案
export enum SHOW {
  NONE = 0,
  BASE = 1,
  DEMAND = 2,
  INFO = 3,
  ANSWER = 4,
  INTENSION = 5
}

export enum Q_TYPE {
  choice = 1,
  blanks = 2,
  essay = 3,
  scene = 4,
}

export const Q_TYPE_MAP: { [key: number]: string } = {
  [Q_TYPE.choice]: '选择题',
  [Q_TYPE.blanks]: '填空题',
  [Q_TYPE.essay]: '问答题',
  [Q_TYPE.scene]: '情景题'
}

// 用户角色， 1超管 2管理员 3讲师 4学员 5督学师
export enum ROLE {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  TEACHER = 3,
  STU = 4,
  INSPECTOR = 5
}

// 隐私政策
export const yszcUrl = "https://manerai.cn/privacy";

// 服务协议
export const fwxyUrl = "https://manerai.cn/agreement";

// 后端固定套餐特定ID， 1个人版 2专业版 3企业版
export enum PACKAGE {
  Personal = "个人版",
  Professional = "专业版",
  Enterprise = "企业版",
  Customized = "定制版", // 定制版
  Trial = "体验版" // 体验版
}
